import { useContext } from "react";
import { Navigate , Routes, Route, BrowserRouter  } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import PropertiesList from "../pages/properties/PropertiesList";
import PropertyDetails from "../pages/properties/PropertyDetails";
import OnboardingDetails from "../pages/onboarding/OnboardingDetails";
import OnboardingList from "../pages/onboarding/OnboardingList";
import EngagementList from "../pages/engagement/EngagementList";
import ResidentDetails from "../pages/residents/ResidentDetails";
import ResidentList from "../pages/residents/ResidentList";
import FoodOverview from "../pages/food/foodPage"
import RevenuePage from "../pages/revenue/revenueManualPage/revenueManualPage";
import RevenueBulkUploadPage from "../pages/revenue/revenueBulkUploadPage";
import StateList from "../pages/masters/StateList";
import CityList from "../pages/masters/CityList";
import ClusterList from "../pages/masters/ClusterList";
import FoodList from "../pages/masters/FoodList";
import RoomTypeList from "../pages/masters/RoomTypeList";
// import InstitutionList from "../pages/masters/InstitutionList";
import LoginPage from "../pages/login/login";
import PrivateRoutes from "./privateRoute";
import GlobalStateContext from "../GlobalStateContext";
import RevenuePDCPage from "../pages/revenue/revenuePDCPage/revenuePDCPage";
import RevenueDirectPayPage from "../pages/revenue/revenueDirectPayPage/revenueDirectPayPage";
import RevenueDirectPayDetailsPage from '../pages/revenue/revenueDirectPayPage/revenueDirectPayDetailsPage';
import EmployeList from "../pages/masters/EmployeList";
import Room from "../pages/masters/Room";
import SalesList from "../pages/sales/SalesList";
import LeadCreation from "../pages/lead/LeadCreation";
import WebsiteHome from "../pages/website/home-page/index"
import BlogPage from "../pages/website/blog/index"
import EditBlog from "../pages/website/blog/EditBlog";
import CityPage from "../pages/website/city/CityPage"
import CityDetails from "../pages/website/city/cityData/CityDetails"
import PropertyPage from "../pages/website/property/PropertyDetails"
import IstitutionPage from "../pages/website/institution/Institution"
import IstitutionDetailPage from "../pages/website/institution/institutionData/InstitutionDetails"
import FoodVerificationDetails from "../pages/food/FoodVerificationDetails";
import FoodPropertiesList from "../pages/food/FoodPropertiesList";
import TicketList from "../pages/tickets/TicketList";
import TicketDetails from "../pages/tickets/TicketDetails";


const AppRoutes = () =>{
  const {roles} = useContext(GlobalStateContext);

  return(
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care || roles?.legal_team?
            <Route  path="/residents" key={"_/residents__"} element={<ResidentList/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care || roles?.legal_team?
            <Route  path="/tickets" key={"_/tickets__"} element={<TicketList/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care || roles?.legal_team?
            <Route  path="/tickets-details/:ticketId" key={"_/tickets__"} element={<TicketDetails/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care || roles?.legal_team?
            <Route  path="/residents-details/:residentId" key={"_/residents-details/:residentId__"} element={<ResidentDetails/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.sales_admin || roles?.sales_manager || roles.marketing_manager ?
            <Route  path="/properties" key={"_/properties__"} element={<PropertiesList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.sales_admin || roles?.sales_manager || roles.marketing_manager ?
            <Route  path="/property-details/:propertyId" key={"_/property-details/:propertyId__"} element={<PropertyDetails/>} />
            :
            null
          }
            {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.sales_admin || roles?.sales_manager ?
            <Route  path="/food_verification/:propertyId" key={"_/food_verification/:propertyId__"} element={<FoodVerificationDetails/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.sales_admin || roles?.sales_manager ?
            <Route  path="/food_verification" key={"_/food_verification__"} element={<FoodPropertiesList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care ?
            <Route  path="/onboarding" key={"_/onboarding__"} element={<OnboardingList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care ?
            <Route  path="/onboarding-details/:onboardingId" key={"_/onboarding-details/:onboardingId__"} element={<OnboardingDetails/>} />
            :
            null
          }
           {
            roles?.super_admin ?
            <Route  path="/engagement" key={"_/engagement__"} element={<EngagementList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.tech_admin || roles?.food_vp ?
            <Route  path="/food" key={"_/food__"} element={<FoodOverview/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo ?
            <Route  path="/revenue/post-dated-cheque-process" key={"_/revenue/post-dated-cheque-process__"} element={<RevenuePDCPage/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.finance_head || roles?.cfo || roles?.finance_manager || roles?.sales_manager ?
            <Route  path="/revenue/direct-pay" key={"_/revenue/direct-pay__"} element={<RevenueDirectPayPage/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.finance_head || roles?.cfo || roles?.finance_manager || roles?.sales_manager ?
            <Route  path="/revenue/direct-pay/:empName/:id" key={"_/revenue/direct-pay__/:empName/:id"} element={<RevenueDirectPayDetailsPage/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager ?
            <Route  path="/revenue/manual-adjustment-entries" key={"_/revenue/manual-adjustment-entries__"} element={<RevenuePage/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.finance_head || roles?.cfo || roles?.finance_manager  ?
            <Route  path="/revenue/bulk-uploads" key={"_/revenue/bulk-uploads__"} element={<RevenueBulkUploadPage/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/states" key={"_/masters/states__"} element={<StateList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles.sales_central_associate ?
            <Route  path="/sales" key={"_/sales__"} element={<SalesList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/cities" key={"_/masters/cities__"} element={<CityList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/cluster" key={"_/masters/cluster__"} element={<ClusterList/>} />
            :
            null
          }
           {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/food" key={"_/masters/food__"} element={<FoodList/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/room_type" key={"_/masters/room_type__"} element={<RoomTypeList/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/employee" key={"_/masters/employee_"} element={<EmployeList/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/masters/room" key={"_/masters/room_"} element={<Room/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/website/home" key={"_/website/home"} element={<WebsiteHome/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/website/city" key={"_/website/city"} element={<CityPage/>} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/website/city/:id" key={"_/website/city"} element={<CityDetails />} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin ?
            <Route  path="/website/city/:id/property/:propertyId" key={"_/website/city"} element={<PropertyPage />} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin  || roles.marketing_manager ?
            <Route  path="/website/blog" key={"_/website/blog"} element={<BlogPage />} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin  || roles.marketing_manager ?
            <Route  path="/website/blog/:id" key={"_/website/blog/create"} element={<EditBlog />} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin  || roles.marketing_manager ?
            <Route  path="/website/institution" key={"_/website/institution"} element={<IstitutionPage />} />
            :
            null
          }
          {
            roles?.super_admin || roles?.tech_admin  || roles.marketing_manager ?
            <Route  path="/website/institution/:id" key={"_/website/institution"} element={<IstitutionDetailPage />} />
            :
            null
          }
          {/* {
            roles?.super_admin ?
            <Route  path="/masters/institution" key={"_/masters/institution__"} element={<InstitutionList/>} />
            :
            null
          } */}
          {
            roles?.super_admin ?
            <Route  path="*" key={"_/residents*__"} element={<ResidentList/>} />
            :
            null
          }
        </Route>

        <Route element={<LeadCreation />} path="/lead-creation" />

        {
          !secureLocalStorage.getItem("accessToken") ?
            <Route
              path="*" key={"_/*__"}
              element={<Navigate to={{ pathname: '/'}} />}
            />
          : null
        }
        <Route element={<LoginPage/>} path="/"/>

      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
