import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchResidents } from "../../helpers/services/api.services";
import ResidentsConstant from "../../helpers/constants/residents";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import Avatar from 'react-avatar';
import DataTable from 'react-data-table-component';
import { getFiscalYearForListing } from "../../helpers/common/common.function";
import { useFormik } from 'formik';
import { Modal } from "react-bootstrap";
import { fetchCities, fetchClusters } from '../../helpers/services/admin.services';
import { Form } from "react-bootstrap";
import MobileLeadModal from "../../components/customModals/MobileLeadModal";
import { NotificationIcon } from "../../components/notification/NotificationIcon";
import Loader from "../../components/layouts/Loader";

function ResidentList() {
  const navigate = useNavigate();
  const list = useRef();

  const [residentStatuses, setResidentStatuses] = useState([]);
  const [activeResidentStatus, setActiveResidentStatus] = useState("All");

  // Data-table
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [serachObject, setSerachObject] = useState({})
  const [sortObject, setSortObject] = useState({})
  const [cities, setCities] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [filterAcitveCount, setFilterAcitveCount] = useState(0)
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabKey, setTabKey] = useState("")
  const [showLeadModal, setShowLeadModal] = useState(false)

  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleShowFilterModal = () => {
    if (Object.keys(serachObject).length) {
      Object.keys(serachObject).map((key) => {
        formik.setFieldValue(key, serachObject[key])
      })
    }
    setShowFilterModal(true);
  }

  useEffect(() => {
    setResidentStatuses(ResidentsConstant.residentStatus);
    loadResidentsData(page, serachObject, sortObject, rowsPerPage);
    loadCities();
    loadClusters();
  }, []);

  const loadCities = async () => {
    let response = await fetchCities();
    if (response && response.data && response.data.length > 0) {
      let cities = response?.data?.map(element => {
        return { value: element?.id, label: element?.city_name }
      });
      setCities(cities);
    }
  }

  const loadClusters = async () => {
    let response = await fetchClusters();
    if (response && response.data && response.data.length > 0) {
      let clusters = response?.data?.map(element => {
        return { value: element?.id, label: element?.cluster_name }
      });

      setClusters(clusters);
    }
  }

  const loadResidentsData = async (currentPage, searchPayload, sortPayload, current_rowPerPage) => {
    setLoading(true);
    let response = await fetchResidents(currentPage, searchPayload, sortPayload, current_rowPerPage);

    if (response && response?.status === "success" && response.data && response.data.length > 0) {
      setResidents(response.data);

      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }

      setPage(currentPage);
      setLoading(false);
      handleCloseFilterModal();
    } else {
      setResidents([]);
      setLoading(false);
      handleCloseFilterModal();
    }
  };

  const filteredResidentsData = async (status) => {
    setActiveResidentStatus(status);

    // Logic for filter residents data
  };

  const handleDetailsPageRedirection = (data) => {
    navigate(`/residents-details/${data?.id}`);
  }

  const onScroll = async () => {
    if (list.current) {
      const { scrollTop, scrollHeight, clientHeight } = list.current;
      let totalHeight = scrollTop + clientHeight;

      if (Math.round(totalHeight) === scrollHeight) {
        let currentPage = page + 1;
        setPage(currentPage);

        await onScrollFetchResidentData(currentPage);
      }
    }
  };

  const onScrollFetchResidentData = async (currentPage) => {
    let response = await fetchResidents(currentPage, serachObject, sortObject, rowsPerPage);

    if (response && response?.status === "success" && response.data && response.data.length > 0) {
      setResidents(onboardingRows => [...onboardingRows, ...response.data]);
    }
  }

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.resident_name,
      width: "20%",
      cell: row => (
        <div>
          <Avatar
            name={row?.resident_name ? row.resident_name.charAt(0) : ''}
            color={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
            round={true}
            size="24" />
          <span className="user_name">{row?.resident_name}</span>
        </div>
      ),
      sortable: true,
      sortField: 'resident_name',
    },
    {
      name: 'Resident ID',
      selector: (row) => row.id,
      sortable: true,
      sortField: 'id'
    },
    {
      name: 'Property',
      selector: (row) => row.property_name,
      // sortable: true,
      // sortField:'property_name'
    },
    // {
    //   name: 'Phone No',
    //   selector: (row) => row.mobile_number,
    //   sortable: true,
    //   sortField:'mobile_number'
    // },
    {
      name: 'City',
      selector: (row) => row?.city_name,
      // sortable: true,
      // sortField:'city_name'
    },
    // {
    //   name: 'Unpaid amount',
    //   selector: (row) => 0,
    //   sortable: true,
    // },
    // {
    //   name: 'PM',
    //   selector: (row) => 'Brooklyn Simmons',
    //   sortable: true,
    // },
    // {
    //   name: 'Status',
    //   width: "15%",
    //   selector: (row) => row?.basic_details_status,
    //   cell: row => (
    //     <span className="success_status_badge">{row?.current_status}</span>
    //   ),
    //   sortable: true,

    // },
    {
      name: 'Status',
      width: "15%",
      selector: (row) => row?.operational_status,
      cell: row => (
        <span className="success_status_badge">{row?.operational_status}</span>
      ),
      // sortable: true,

    },
    {
      name: '',
      selector: (row) => row?.id,
      width: "3%",
      cell: row => (
        <Link to={`/residents-details/${row?.id}`}>
          <img src="/images/right_arrow.svg" alt="Details Icon" />
        </Link>
      )
    },
  ];

  const handlePageChange = (page) => {
    loadResidentsData(page, serachObject, sortObject, rowsPerPage);
  };

  const handleSort = async (column, sortDirection) => {
    let sort_obj = {
      sort_by: column.sortField,
      sort_direction: sortDirection
    }

    if (column.sortField) {
      setSortObject(sort_obj)
      loadResidentsData(page, serachObject, sort_obj, rowsPerPage);
    }
  };


  const initialValues = {
    resident_name: "",
    id: "",
    mobile_number: "",
    email: "",
    city_id: "",
    property_code: "",
    cluster_id: "",
  }

  const validationSchema = null

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {

      let search_obj = JSON.parse(JSON.stringify(values))
      const has_value = Object.values(values).some(val => val ? true : false)
      Object.keys(values).map((key) => {
        if (!values[key]) {
          delete search_obj[key]
        }
      })
      if (has_value) {
        setSerachObject(search_obj);
        setFilterAcitveCount(Object.keys(search_obj).length)
        loadResidentsData(1, search_obj, sortObject, rowsPerPage);
      }
    },
  });

  const handleClickFilterClose = (e) => {
    e.stopPropagation()
    setSerachObject({})
    setFilterAcitveCount(0)
    formik.resetForm();
    loadResidentsData(1, { operational_status: tabKey.toLowerCase() }, sortObject, rowsPerPage);
  }

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    // console.log("currentRowsPerPage, currentPage -- ",currentRowsPerPage, currentPage)
    setPage(currentPage)
    setRowsPerPage(currentRowsPerPage)
    loadResidentsData(currentPage, {operational_status : tabKey.toLowerCase()}, sortObject, currentRowsPerPage);
  }

  const handleResetFilter = (e) => {
    formik.resetForm();
  }


  return (
    <>
      <div className="main-content-web">

        {/* HEADER */}
        <div className="breadcumb-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div>
              <div className="d-flex pb-2">
                <h2>Residents - <span className="year-text">{getFiscalYearForListing()}</span></h2>
              </div>
              <div className="breadcum">
                <ul>
                  <li><a href="#">Tenants</a> <span>/</span></li>
                  <li>Residents</li>
                </ul>
              </div>
            </div>
            <div className="create-prop d-flex align-items-center gap-4">
              <button className="basic-button" onClick={() => setShowLeadModal(!showLeadModal)}>
                <img
                  src="/images/plus-circle.svg"
                  className="pr-2"
                  alt=""
                />
                New Lead
              </button>
              <NotificationIcon />
            </div>
          </div>
        </div>

        {/* STATUS FILTER */}
        <div className="d-flex justify-content-between align-items-center pb-32">
          <div className="status-box">
            {residentStatuses.map((status, index) => (
              <a
                key={index}
                href="#"
                className={status?.label === activeResidentStatus ? "active" : ""}
                // onClick={() => filteredResidentsData(status?.label)}
                onClick={() => {
                  // let search_obj = {...serachObject,status:status.key}
                  let search_obj = { ...serachObject, operational_status: status.key }
                  setSerachObject(search_obj)
                  setTabKey(status.key)
                  filteredResidentsData(status?.label);
                  loadResidentsData(1, search_obj, sortObject, rowsPerPage);
                }}
              >
                {status?.label}
              </a>
            ))}
          </div>
          <div className="status-box">
            <a
              onClick={handleShowFilterModal}
              className="cursor-pointor"
              style={{
                display: "flex",
                direction: 'row'
              }}
            >
              <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
              {
                filterAcitveCount > 0 ?
                  <>
                    <div
                      style={{
                        width: 22,
                        height: 22,
                        backgroundColor: '#FF5700',
                        borderRadius: "50%",
                        marginLeft: 4,
                        color: '#FFF4ED',
                        textAlign: 'center',
                        justifyContent: 'center',
                        font: 'Manrope',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fontWeight: 800,
                        letterSpacing: '0.88px',
                      }}
                    >
                      {filterAcitveCount}
                    </div>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                      }}
                      onClick={handleClickFilterClose}
                    >
                      <img src="/images/close_with_bg.svg" className="pr-1" alt="" />
                    </button>
                  </>
                  : null
              }
            </a>
          </div>
        </div>

        <div className="sr_data_table">
          <DataTable
            columns={columns}
            data={residents}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            fixedHeader={true}
            fixedHeaderScrollHeight={'430px'}
            onRowClicked={handleDetailsPageRedirection}
            onSort={handleSort}
            sortServer
            onChangeRowsPerPage={handleRowsPerPageChange}
          ></DataTable>
        </div>
      </div>

      {/* RESPONSIVE UI */}
      <div className="main-content-mobile">
        {/* =========================== */}
        <div className="table_main_tit d-flex justify-content-between align-items-center  list-header-mob">
          <div>
            <h2>Resident</h2>
            <h5>Total <b>{residents.length}</b> Residents</h5>
          </div>
          <div className="status-box d-flex">
            <a href="#" onClick={() => handleShowFilterModal()} className="d-flex">
              <img src="/images/filter-orange.svg" className="pr-1" alt="" />
              <span style={{ marginTop: filterAcitveCount > 0 ? 3 : 0 }}>Filter</span>
              {
                filterAcitveCount > 0 ?
                  <>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: '#FF5700',
                        borderRadius: "50%",
                        marginLeft: 4,
                        color: '#FFF4ED',
                        textAlign: 'center',
                        justifyContent: 'center',
                        font: 'Manrope',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fontWeight: 800,
                        letterSpacing: '0.88px',
                        marginTop: 3,
                      }}
                    >
                      {filterAcitveCount}
                    </div>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                      }}
                      onClick={handleClickFilterClose}
                    >
                      <img src="/images/close_with_bg.svg" className="pr-1" alt="" />
                    </button>
                  </>
                  : null
              }
            </a>
            <NotificationIcon />
          </div>
        </div>
        {/* =========================== */}
        <div
          className="list_scroll_mob"
          onScroll={() => onScroll()}
          ref={list}
        >
          {
            loading ? <>
            <Loader />
            </> :
              <>
                {(residents && residents.length > 0) ? residents?.map((item, index) => (
                  <Link to={`/residents-details/${item?.id}`} key={index}>
                    <div className="brd_box_card pd-20-16 list_main_mob">
                      {/* =========================== */}
                      <div className="d-flex justify-content-between align-items-center list_brd_btm_mob">
                        <div className="d-flex align-items-center">
                          <div className="img_round_icon_box">
                            <img src="/images/Ellips1.png" alt="" />
                          </div>
                          <div className="mob_list_data">
                            <h2>{item?.resident_name}</h2>
                            <h4>{item?.ys_id}</h4>
                          </div>
                        </div>
                        <div>
                          <span className="bg_green_txt">{item?.operational_status}</span>
                        </div>
                      </div>
                      {/* =========================== */}
                      <div className="row">
                        <div className="col">
                          <div className="mob-list-sub-data">
                            <h5>Property</h5>
                            <h3>{item?.property_name ?? "--"}</h3>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mob-list-sub-data">
                            <h5>City</h5>
                            <h3>{item?.city_name ?? "--"}</h3>
                          </div>
                        </div>
                        {/* <div className="col">
                    <div className="mob-list-sub-data">
                      <h5>PM</h5>
                      <h3>Kristin Watson</h3>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mob-list-sub-data">
                      <h5>Unpaid Amount</h5>
                      <h3>₹ 0</h3>
                    </div>
                  </div> */}
                      </div>
                      {/* =========================== */}
                    </div>
                  </Link>
                )) : (
                  <div>No Resident Found</div>
                )}
              </>
          }

          {/* <div className="brd_box_card pd-20-16 list_main_mob">
            <div className="d-flex justify-content-between align-items-center list_brd_btm_mob">
              <div className="d-flex align-items-center">
                <div className="img_round_icon_box">
                  <img src="/images/Ellips1.png" alt="" />
                </div>
                <div className="mob_list_data">
                  <h2>Robert Fox</h2>
                  <h4>CRM2324oan</h4>
                </div>
              </div>
              <div>
                <span className="bg_warning_txt">Exit</span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Property</h5>
                  <h3>YSCT022204</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>PM</h5>
                  <h3>Kristin Watson</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Unpaid Amount</h5>
                  <h3>₹ 10,000</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="brd_box_card pd-20-16 list_main_mob">
            <div className="d-flex justify-content-between align-items-center list_brd_btm_mob">
              <div className="d-flex align-items-center">
                <div className="img_round_icon_box">
                  <img src="/images/Ellips1.png" alt="" />
                </div>
                <div className="mob_list_data">
                  <h2>Courtney Henry</h2>
                  <h4>CRM2324oan</h4>
                </div>
              </div>
              <div>
                <span className="bg_danger_txt">Absconded </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Property</h5>
                  <h3>YSCT022204</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>PM</h5>
                  <h3>Kristin Watson</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Unpaid Amount</h5>
                  <h3>₹ 10,000</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="brd_box_card pd-20-16 list_main_mob">
            <div className="d-flex justify-content-between align-items-center list_brd_btm_mob">
              <div className="d-flex align-items-center">
                <div className="img_round_icon_box">
                  <img src="/images/Ellips1.png" alt="" />
                </div>
                <div className="mob_list_data">
                  <h2>Courtney Henry</h2>
                  <h4>CRM2324oan</h4>
                </div>
              </div>
              <div>
                <span className="bg_danger_txt">Absconded </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Property</h5>
                  <h3>YSCT022204</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>PM</h5>
                  <h3>Kristin Watson</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Unpaid Amount</h5>
                  <h3>₹ 10,000</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="brd_box_card pd-20-16 list_main_mob">
            <div className="d-flex justify-content-between align-items-center list_brd_btm_mob">
              <div className="d-flex align-items-center">
                <div className="img_round_icon_box">
                  <img src="/images/Ellips1.png" alt="" />
                </div>
                <div className="mob_list_data">
                  <h2>Courtney Henry</h2>
                  <h4>CRM2324oan</h4>
                </div>
              </div>
              <div>
                <span className="bg_danger_txt">Absconded </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Property</h5>
                  <h3>YSCT022204</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>PM</h5>
                  <h3>Kristin Watson</h3>
                </div>
              </div>
              <div className="col">
                <div className="mob-list-sub-data">
                  <h5>Unpaid Amount</h5>
                  <h3>₹ 10,000</h3>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* FILTER MODAL */}
      <Modal
        show={showFilterModal}
        onHide={handleCloseFilterModal}
        centered
        size='lg'
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <img src="/images/cross_modal.svg" className="cursor-pointor" alt="Close Icon" onClick={handleCloseFilterModal} />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">Resident Name</label>
                  <input
                    type="text"
                    id="residentName"
                    name="residentName"
                    placeholder="Enter Name"
                    className="form-control"
                    value={formik.values.resident_name}
                    onChange={(e) => formik.setFieldValue('resident_name', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">Resident Id</label>
                  <input
                    type="text"
                    id="residentId"
                    name="residentId"
                    placeholder="Enter Resident Id"
                    className="form-control"
                    value={formik.values.id}
                    onChange={(e) => formik.setFieldValue('id', e.target.value.trim())}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">Mobile Number</label>
                  <input
                    type="number"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="Enter Mobile Number"
                    className="form-control"
                    value={formik.values.mobile_number}
                    onChange={(e) => formik.setFieldValue('mobile_number', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">Resident Email</label>
                  <input
                    type="email"
                    id="residentEmail"
                    name="residentEmail"
                    placeholder="Enter Resident Email"
                    className="form-control"
                    value={formik.values.email}
                    onChange={(e) => formik.setFieldValue('email', e.target.value)}
                  />
                </div>
              </div>
              <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                <Form.Label>City</Form.Label>
                <Form.Select
                  placeholder="Select City"
                  id="city_id"
                  isInvalid={formik.touched.city_id && formik.errors.city_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city_id}
                  aria-label="Default select example">
                  <option value="">Select City</option>
                  {
                    cities.map(city => <>
                      <option value={city.value}>{city.label}</option>
                    </>)
                  }
                </Form.Select>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyCode">Property Code</label>
                  <input
                    type="text"
                    id="propertyCode"
                    name="propertyCode"
                    placeholder="Enter Property Code"
                    className="form-control"
                    value={formik.values.property_code}
                    onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
                  />
                </div>
              </div>
              <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                <Form.Label>Cluster</Form.Label>
                <Form.Select
                  placeholder="Select cluster"
                  id="cluster_id"
                  isInvalid={formik.touched.cluster_id && formik.errors.cluster_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cluster_id}
                  aria-label="Default select example">
                  <option value="">Select cluster</option>
                  {
                    clusters.map(cluster => <>
                      <option value={cluster.value}>{cluster.label}</option>
                    </>)
                  }
                </Form.Select>
              </div>

              <div className="modal_btn_footer mt-4">
                <button type="button" className="orange_brd_button p-12-16-button mr-3" style={{ width: 'fit-content' }} onClick={() => handleResetFilter()}>RESET</button>
                <button type="submit" className={'basic-button'}>SEARCH</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {showLeadModal && <MobileLeadModal setShowModal={setShowLeadModal} showModal={showLeadModal} />}
    </>
  );
}

export default withDefaultDashBoardLayout(ResidentList);