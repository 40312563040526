import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sidebarItems } from "../../../helpers/constants/sidebar-items";
import { useEffect, useState } from "react";
import GlobalStateContext from "../../../GlobalStateContext";
import SvgIcon from '@mui/material/SvgIcon';
import { Modal, Tab, Tabs } from "react-bootstrap";
import styles from '../../../pages/revenue/revenueManualPage/modals/UploadAdjustmentModal/index.module.scss'
import { googleLogout } from '@react-oauth/google';
import secureLocalStorage from "react-secure-storage";
import MobileLeadModal from "../../customModals/MobileLeadModal";

function LogoutIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg fill={props.color} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" /></svg>
        </SvgIcon>
    );
}

function Sidebar() {
    const { roles, setRoles } = useContext(GlobalStateContext);
    const [menuItems, setMenuItems] = useState([...sidebarItems]);
    const [activeRoute, setActiveRoute] = useState(null);
    const [showConfirmLogout, SetShowConfirmLogout] = useState(false);
    const [showLeadModal , setShowLeadModal] = useState(false)

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setupMenuItems(pathname);
        // eslint-disable-next-line
    }, [pathname])

    const setupMenuItems = (path) => {
        if (path) {
            setActiveRoute(path);
            const updatedMenuItems = updateMenuItems([...sidebarItems], path);
            setMenuItems(updatedMenuItems);
        }
    }

    // Set Active Routes
    const updateMenuItems = (items, route) => {
        return items.map(item => {
            // allowRoutes
            item.isShowSubMenu = false;
            if (item.subItem && item.subItem.length > 0) {
                let updatedSubItems = updateMenuItems(item.subItem, route);
                updatedSubItems = updatedSubItems.map(subItem => {
                    if (route !== "/" && subItem.allowRoutes.includes(route)) {
                        return { ...subItem, isActive: true };
                    } else {
                        return { ...subItem, isActive: false };
                    }
                });

                const isAllowed = route !== "/" && item.allowRoutes.includes(route);
                return { ...item, subItem: updatedSubItems, isShowSubMenu: false, isActive: isAllowed };
            } else {
                return { ...item, isShowSubMenu: false, isActive: route && route !== "/" && item.path.includes(route) };
            }
        });
    };

    // Active Sub Menu
    const openSubMenu = (key) => {
        let menuItemList = [...menuItems].map((e) => {
            e.isShowSubMenu = false;
            return e;
        });
        menuItemList[key].isShowSubMenu = menuItemList[key].isShowSubMenu ? false : true;
        setMenuItems(menuItemList);
    }

    const onClickSubMenu = (index) => {
        setMenuItems((prevList) => {
            let st = [...prevList];
            st[index].isShowSubMenu = false;
            return st;
        });
    }

    const handleConfirmLogout = async () => {
        await googleLogout();
        setRoles({})
        // await localStorage.removeItem("accessToken")
        // await localStorage.removeItem("roles")
        await secureLocalStorage.removeItem("accessToken")
        await secureLocalStorage.removeItem("roles")
        setTimeout(() => {
            navigate("/")
        }, 500)
    }
    // comment

    return (<>
        <nav id="sidebar" className="left-bar">
            <div className="left-menu-box">
                <h1>
                    <Link rel="noreferrer" target="_blank" to={'/'} className="logo main_logo">
                        <img src="/images/logo.svg" alt="" />
                    </Link>
                </h1>
                <ul className="list-unstyled components mb-5">
                    {
                        menuItems.map((item, index) =>
                            (item?.key === "properties" && (roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.sales_admin || roles?.sales_manager || roles.marketing_manager)) ||
                                (item?.key === "tenants" && (roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care || roles?.legal_team)) ||
                                (item?.key === "revenue" && (roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.operations_head || roles?.cluster_manager || roles?.sales_manager)) ||
                                (item?.key === "website" && (roles?.super_admin || roles?.tech_admin  || roles.marketing_manager)) ||
                                (item?.key === "tenants" && (roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.tech_admin || roles?.customer_care || roles?.legal_team)) ||
                                (item?.key === "revenue" && (roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager || roles?.sales_manager)) ||
                                (item?.key === "masters" && (roles?.super_admin || roles?.tech_admin)) ||
                                (item?.key === "sales" && (roles?.super_admin || roles?.sales_central_associate )) ||
                                (item?.key === "food" && (roles?.super_admin || roles?.tech_admin || roles?.food_vp)) ?
                                (item?.subItem && item?.subItem.length > 0) ? (
                                    // Menu with items
                                    <li key={item?.key} className={item?.isActive ? 'active' : ''}>
                                        <Link href="#" className="dropdown-toggle" onClick={(e) => { e.preventDefault(); openSubMenu(index) }}>
                                            <span><img src={'/images/' + item?.icon} alt={item?.label} /></span>
                                            {item?.label}
                                        </Link>
                                        {/* show */}
                                        {/* <div className="overflow-hidden"> */}
                                        <ul className={`collapse list-unstyled ${item?.isShowSubMenu ? 'show' : ''}`}>
                                            {
                                                item?.subItem.map((subMenu) => (subMenu?.key === "manual-adjustment-entries" && !(roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager)) ||
                                                    // (subMenu?.key === "onboarding" && (roles?.customer_care || roles?.legal_team) && !(roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo)) ||
                                                    (subMenu?.key === "onboarding" && roles?.legal_team && !(roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo)) ||
                                                    (subMenu?.key === "post-dated-cheque-process" && !(roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo)) ||
                                                    (subMenu?.key === "direct-pay" && !(roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager || roles?.finance_head || roles?.cfo || roles?.finance_manager || roles?.sales_manager)) ||
                                                    (subMenu?.key === "bulk-uploads" && !(roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo || roles?.operations_head || roles?.cluster_manager))
                                                    ?
                                                    null :
                                                    <li key={subMenu?.key} className={(subMenu?.isActive) ? 'active' : ''}>
                                                        <Link to={subMenu?.path} onClick={() => { onClickSubMenu(index) }}>
                                                            <img className="mr-2" src={'/images/' + subMenu?.icon} alt={subMenu?.label} />
                                                            <span className="mb-0"> {subMenu?.label}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        {/* </div> */}
                                    </li>
                                ) : (
                                    // Menu without item
                                    <li key={item?.key} className={item?.isActive ? 'active' : ''}>
                                        <Link to={item?.path}>
                                            <span><img src={'/images/' + item?.icon} alt={item?.label} /></span>
                                            {item?.label}
                                        </Link>
                                    </li>
                                )
                                : null
                        )}
                    <li key={"Logout"} className={''} onClick={() => SetShowConfirmLogout(true)}>
                        <Link to={''}>
                            <span><LogoutIcon fontSize="small" color="#67BDD4" /></span>
                            {"Logout"}
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>

        <div className="mobile-layout">
            <div className="mob-bottom-menu-lead" onClick={()=> setShowLeadModal(!showLeadModal)}>
                    New Lead
            </div>
            <div className="mob-bottom-menu">
                <div className="row">
                    <div className="col">
                        <Link to={'/onboarding'} className={(activeRoute === '/onboarding' || activeRoute === '/onboarding-details') ? 'active' : ''}>
                            <i> <img src="/images/menu1_icn.svg" alt="" /></i>
                            <span>Onboarding</span>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={'/residents'} className={(activeRoute === '/residents' || activeRoute === '/residents-details') ? 'active' : ''}>
                            <i> <img src="/images/menu1_icn.svg" alt="" /></i>
                            <span>Resident</span>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={'/food_verification'} className={(activeRoute === '/food_verification') ? 'active' : ''}>
                            <i> <img src="/images/menu1_icn.svg" alt="" /></i>
                            <span>Food Scanner</span>
                        </Link>
                    </div>
                    {/* <div className="col">
                        <Link to={'/'}>
                            <i> <img src="/images/profile_menu.svg" alt="" /></i>
                            <span>Profile</span>
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>

        {/* CONFIRM LOGOUT MODAL */}
        <Modal
            show={showConfirmLogout}
            onHide={() => SetShowConfirmLogout(false)}
            backdrop="static"
            dialogClassName={styles['dialog-container']}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={styles['#modal-title']}>LOGOUT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['body-content']}>
                    <div>
                        <p className={"mb-0 " + styles['label-title']}>Are you sure you want logout?</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn ' + styles['btn-cancel']} onClick={() => SetShowConfirmLogout(false)} >CANCEL</button>
                <button className={'btn ' + styles['btn-submit']} onClick={handleConfirmLogout} >LOGOUT</button>
            </Modal.Footer>
        </Modal>
        {showLeadModal && <MobileLeadModal showModal={showLeadModal}  setShowModal={setShowLeadModal} />}
    </>
    );
}

export default Sidebar;
